import { Routes, RouterModule } from '@angular/router';

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('../../client/client.module').then(m => m.ClientModule)
  },
  {
    path: 'dispatch',
    loadChildren: () => import('../../dispatch/dispatch.module').then(m => m.DispatchModule)
  },
  {
    path: 'stock',
    loadChildren: () => import('../../stock/stock.module').then(m => m.StockModule)
  },
  {
    path: 'sales',
    loadChildren: () => import('../../sales/sales.module').then(m => m.SalesModule)
  },
  {
    path: 'stores',
    loadChildren: () => import('../../stores/stores.module').then(m => m.StoresModule)
  },
  {
    path: 'brands',
    loadChildren: () => import('../../brands/brands.module').then(m => m.BrandsModule)
  },
  
  {
    path: 'users',
    loadChildren: () => import('../../users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('../../payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'credit',
    loadChildren: () => import('../../credit/credit.module').then(m => m.CreditModule)
  },

  {
    path: 'reports',
    loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
  }
];
